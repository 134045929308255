/* Commented out harmful/unused styles for devices */

/*@font-face {
  font-family: 'pignose-calendar-icon';
  src: url("../fonts/pignose.calendar.eot?gpa4vl");
  src: url("../fonts/pignose.calendar.eot?gpa4vl#iefix") format('embedded-opentype'), url("../fonts/pignose.calendar.ttf?gpa4vl") format('truetype'), url("../fonts/pignose.calendar.woff?gpa4vl") format('woff'), url("../fonts/pignose.calendar.svg?gpa4vl#pignose.calendar") format('svg');
  font-weight: normal;
  font-style: normal;
}*/
.pignose-calendar .icon-arrow-left,
.pignose-calendar .icon-arrow-right {
  /*font-family: 'pignose-calendar-icon' !important;*/
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
/*.pignose-calendar .icon-arrow-left:before {
  content: '\e90b';
}
.pignose-calendar .icon-arrow-right:before {
  content: '\e90a';
}*/
.pignose-calendar-wrapper {
  display: none;
  position: fixed;
  width: 80%;
  max-width: 360px;
  top: 50%;
  left: 50%;
  border-radius: 2px;
  z-index: 50001;
  overflow: hidden;
  /*-webkit-box-shadow: 0 4px 16px #000000;
          box-shadow: 0 4px 16px #000000;
  -webkit-transform: translate3d(0, 160px, 0);
          transform: translate3d(0, 160px, 0);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.5s ease-out;
  transition: opacity 0.3s ease, -webkit-transform 0.5s ease-out;
  transition: opacity 0.3s ease, transform 0.5s ease-out;
  transition: opacity 0.3s ease, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;*/
}
/*.pignose-calendar-wrapper.pignose-calendar-wrapper-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}*/
.pignose-calendar-wrapper .pignose-calendar {
  /*max-width: auto;*/
  width: 100%;
  border: none;
}
.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group {
  border-top: 1px solid #e2e2e2;
  overflow: hidden;
}
.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button {
  width: 50%;
  display: block;
  float: left;
  height: 3.2em;
  text-align: center;
  line-height: 3.2em;
  color: #333333;
  font-weight: 600;
  text-decoration: none;
  /*-webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;*/
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button:hover {
  background-color: #efefef;
}
.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button-apply {
  color: #ffffff;
  background-color: #2fabb7;
}
.pignose-calendar-wrapper .pignose-calendar .pignose-calendar-button-group .pignose-calendar-button-apply:hover {
  background-color: #49c4d0;
}
.pignose-calendar-wrapper-overlay {
  background-color: #000000;
  /*opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;
}
/*.pignose-calendar-wrapper-overlay.pignose-calendar-wrapper-overlay-active {
  opacity: 0.7;
}*/
.pignose-calendar {
  width: 80%;
  max-width: 360px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  font-size: 100%;
  margin: 0 auto;
  /*-webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);*/
}
.pignose-calendar .pignose-calendar-top {
  padding: 2.6em 0;
  background-color: #fafafa;
  border-bottom: 1px solid #e2e2e2;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  position: relative;
  overflow: hidden;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-date {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.8em 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-year,
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-month {
  display: block;
  text-align: center;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-year {
  font-size: 115%;
  color: rgba(0, 0, 0, 0.5);
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-month {
  margin-bottom: 0.4em;
  font-size: 130%;
  font-weight: 600;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav {
  display: block;
  width: 1.6em;
  height: 1.6em;
  position: relative;
  z-index: 5;
  text-decoration: none;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-value {
  display: block;
  color: #777777;
  font-size: 115%;
  font-weight: 600;
  vertical-align: middle;
  margin-top: -10px;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-icon {
  color: #555555;
  font-size: 160%;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-prev {
  float: left;
  margin-left: 1.6em;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-prev .pignose-calendar-top-value {
  margin-left: 0.2em;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-next {
  float: right;
  margin-right: 1.6em;
}
.pignose-calendar .pignose-calendar-top .pignose-calendar-top-nav.pignose-calendar-top-next .pignose-calendar-top-value {
  margin-right: 0.2em;
}
.pignose-calendar .pignose-calendar-header {
  padding: 0 1.2em;
  margin-top: 1.2em;
  font-weight: 600;
  overflow: hidden;
}
.pignose-calendar .pignose-calendar-header .pignose-calendar-week {
  float: left;
  width: 14.28%;
  height: 2.8em;
  text-align: center;
  line-height: 2.8em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.pignose-calendar .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sun,
.pignose-calendar .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sat {
  color: #fa4832;
}
.pignose-calendar .pignose-calendar-header .pignose-calendar-week:last-child {
  width: 14.32%;
}
.pignose-calendar .pignose-calendar-body {
  padding: 1.2em;
}
.pignose-calendar .pignose-calendar-body .pignose-calendar-row {
  overflow: hidden;
}
.pignose-calendar .pignose-calendar-unit {
  float: left;
  display: block;
  height: 3.8em;
  width: 14.28%;
  text-align: center;
  line-height: 2.8em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.pignose-calendar .pignose-calendar-unit:last-child {
  width: 14.32%;
}
.pignose-calendar .pignose-calendar-unit .pignose-calendar-button-schedule-container {
  line-height: 0.5em;
}
.pignose-calendar .pignose-calendar-unit .pignose-calendar-button-schedule-container .pignose-calendar-button-schedule-pin {
  display: block;
  background-color: #777777;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 0.2em;
}
.pignose-calendar .pignose-calendar-unit .pignose-calendar-button-schedule-container .pignose-calendar-button-schedule-pin:last-child {
  margin-right: 0;
}
.pignose-calendar .pignose-calendar-unit a {
  display: block;
  width: 2.4em;
  height: 2.4em;
  border-radius: 50%;
  color: #49c4d0;
  line-height: 2.4em;
  text-align: center;
  text-decoration: none;
  /*-webkit-transition: background-color 0.3s ease, color 0.3s ease;
  transition: background-color 0.3s ease, color 0.3s ease;*/
}
.pignose-calendar .pignose-calendar-unit a:active {
  background-color: #d8d8d8;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-disabled a {
  /*opacity: 0.5;*/
  background-color: #efefef;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #2fabb7;
  color: #ffffff;
  font-weight: 600;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active.pignose-calendar-unit-sun a,
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active.pignose-calendar-unit-sat a {
  color: #ffffff;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range a {
  background-color: #efefef;
  border-radius: 0;
  width: 100%;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-disabled a {
  color: #b2b9bb;
  background-color: #e1e1e1;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-range-first a {
  border-top-left-radius: 1.2em;
  border-bottom-left-radius: 1.2em;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-range-last a {
  border-top-right-radius: 1.2em;
  border-bottom-right-radius: 1.2em;
}
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-sun a,
.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-sat a {
  color: #fa4832;
}
.pignose-calendar.pignose-calendar-default .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle-active a {
  color: #cccccc !important;
}
.pignose-calendar.pignose-calendar-default.pignose-calendar-reverse .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle-inactive a {
  color: #cccccc !important;
}
.pignose-calendar.pignose-calendar-dark {
  border-color: #323537;
  background-color: #4b4f51;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-top {
  background-color: #3f4244;
  border-bottom-color: #323537;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-month {
  color: #ffffff;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-year {
  color: #bdc2c5;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-value {
  color: #a2a9ab;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-icon {
  color: #a2a9ab;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-header .pignose-calendar-week {
  color: #bdc2c5;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-header.pignose-calendar-week-sun,
.pignose-calendar.pignose-calendar-dark .pignose-calendar-header.pignose-calendar-week-sat {
  color: #ff6060;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit a {
  color: #51cfd2;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sun a,
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sat a {
  color: #ff6060;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-disabled a {
  color: #868e8f;
  background-color: #5d6365;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  color: #ffffff;
  background-color: #31bbbf;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle a {
  color: #8b8f94;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range a {
  background-color: #5a5d62;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-disabled a {
  color: #727a7c;
  background-color: #4f5558;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group {
  border-top: 1px solid #323537;
  overflow: hidden;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group .pignose-calendar-button {
  color: #ffffff;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group .pignose-calendar-button:hover {
  background-color: #5a5d62;
}
.pignose-calendar.pignose-calendar-dark .pignose-calendar-button-group .pignose-calendar-button-apply {
  color: #ffffff;
  background-color: #31bbbf;
}
.pignose-calendar.pignose-calendar-blue {
  background-color: #fafafa;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-top {
  background-color: #009fe3;
  border-bottom-color: #e1e1e1;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-month {
  color: #ffffff;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-year {
  color: #ffffff;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-value {
  color: #ffffff;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-top .pignose-calendar-top-nav .pignose-calendar-top-icon {
  color: #ffffff;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week {
  color: #5c6270;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sun,
.pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sat {
  color: #fa4832;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit a {
  color: #5c6270;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sun a,
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sat a {
  color: #fa4832;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-disabled a {
  background-color: #efefef;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  color: #ffffff;
  background-color: #009fe3;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-toggle a {
  color: #cccccc;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range a {
  background-color: #efefef;
}
.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range.pignose-calendar-unit-disabled a {
  background-color: #efefef;
}
